import moment from "moment";
import React, { useState, useEffect } from "react";

export const DateTime = () => {
  var [date, setDate] = useState(new Date());
  const [displayDate, setDisplayDate] = useState<string>("");

  useEffect(() => {
    var timer = setInterval(() => {
      let dt = new Date();
      let hours = dt.getHours();
      let _hours = hours.toString().padStart(2, "0");
      let minutes = dt.getMinutes();
      let _minutes = minutes.toString().padStart(2, "0");
      setDisplayDate(`${_hours}:${_minutes} `);
      setDate(new Date());
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  },[]);

  return (
    <div>
      <p style={{ marginBottom: 0 }}>
        {displayDate}
        {moment(date, "DD MM YYY").format("dddd DD MMM yyy")}
      </p>
    </div>
  );
};

export default DateTime;
