import { GlobalRouter } from "@routes/index";

import "@styles/index.scss";
import "antd/dist/antd.less";
import { useContext, useEffect } from "react";

function App() {
  useEffect(() => {}, []);

  return <GlobalRouter />;
}

export default App;
