import styles from "./screen.module.scss";
import ArrowDown from "@assets/SVG/ArrowDown.png";
import Lottie from "react-lottie";
import VectorF from "@assets/SVG/VectorF.png";
import VectorB from "@assets/SVG/VectorB.png";
import VectorC from "@assets/SVG/VectorC.png";
import VectorE from "@assets/SVG/VectorE.png";
import Arivals from "@assets/SVG/Arivals.svg";
import animationData from "@assets/Json/J_75.json";
import { Col, Image, Row } from "antd";
import DateTime from "./DateTime";

export default function JSignBoard() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} />
    </div>
  );
}

export const Screen8 = () => {
  return (
    <div
      className="screen8"
      style={{
        background: styles.darkBlue,
        height: "100vh",
      }}
    >
      <div
        style={{
          background: styles.darkBlue,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="header" style={{ margin: "30px 0px 19px 0px" }}>
          Selamat Datang
        </div>
        <div className="headertwo" style={{ margin: "0px 0px 30px 0px" }}>
          Welcome
        </div>
        <div />
        <div style={{ marginTop: "99px" }} className="flexBetween">
          <div style={{ marginTop: "50px", marginRight: "20px" }}>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Col>
                <div className="textVariant2h">Lif untuk</div>
                <div className="textTitles3h">Lift for</div>
              </Col>
              <Col style={{ margin: "30px 0px 30px 20px" }}>
                <Image
                  width={78}
                  height={139}
                  src={ArrowDown}
                  preview={false}
                />
              </Col>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "9px",
                    borderRadius: "9.78px",
                    border: "2px solid white",
                  }}
                >
                  <br />
                  <Image
                    //   width={28}
                    height={80}
                    src={VectorB}
                    preview={false}
                  />{" "}
                  <br />
                  <Image
                    // width={28}
                    height={80}
                    src={VectorE}
                    preview={false}
                  />{" "}
                  <br />
                  <Image
                    // width={28}
                    height={80}
                    src={VectorF}
                    preview={false}
                  />{" "}
                  <br />
                  <Image
                    // width={28}
                    height={80}
                    src={VectorC}
                    preview={false}
                  />{" "}
                  <br />
                </div>
              </Col>
            </Row>
          </div>
          <div
            style={{
              marginLeft: "20px",
              position: "relative",
              width: "608px",
              height: "1008px",
            }}
          >
            <JSignBoard />
          </div>
        </div>
        <div style={{ marginTop: "-100px", marginLeft: "-220px" }}>
          <Image src={Arivals} preview={false} />
        </div>
        <div className="textVariantDate" style={{ marginTop: "340px" }}>
          <DateTime />
        </div>
      </div>
    </div>
  );
};
