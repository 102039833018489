import {
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  Screen5,
  Screen6,
  Screen7,
  Screen8,
  Screen9,
  Screen10,
  Screen1Old,
  // Screen11
} from "@modules/index";
import { UnAuthorized } from "@components/ErrorHandlers/UnAuthorized";

export const globalRoutes = [
  {
    path: "/",
    element: <Screen1 />,
  },
  {
    path: "/screen1",
    element: <Screen1 />,
  },
  {
    path: "/screen2",
    element: <Screen2 />,
  },
  {
    path: "/screen3",
    element: <Screen3 />,
  },
  {
    path: "/screen4",
    element: <Screen4 />,
  },
  {
    path: "/screen5",
    element: <Screen5 />,
  },
  {
    path: "/screen6",
    element: <Screen6 />,
  },
  {
    path: "/screen7",
    element: <Screen7 />,
  },
  {
    path: "/screen8",
    element: <Screen8 />,
  },
  {
    path: "/screen9",
    element: <Screen9 />,
  },
  {
    path: "/screen10",
    element: <Screen10 />,
  },
  // {
  //   path: "/screen11",
  //   element: <Screen11 />,
  // },
  // {
  //   path: "/screen12",
  //   element: <Screen1 />,
  // },
  {
    path: "/unauthorized-entry",
    element: <UnAuthorized />,
  },
];
