import styles from "./screen.module.scss";
import chartRight from "@assets/SVG/chartRight.svg";
import MainTerminal from "@assets/SVG/MainTerminal.svg";
import pLine from "@assets/SVG/PLine.svg";
import { Col, Image, Row, Space } from "antd";
import DateTime from "./DateTime";
import Lottie from "react-lottie";
import Box from "@assets/SVG/Box.svg";
import Group from "@assets/SVG/Group.svg";

import animationData from "@assets/Json/I_43.json";

export default function KSignBoard() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} />
    </div>
  );
}

export const Screen7 = () => {
  return (
    <div
      className="screen7"
      style={{
        background: styles.blue,
        height: "100vh",
        width: "100%",
      }}
    >
      <div style={{ background: styles.blue }}>
        <div className="screen7-header">
          <div className="textVariantPotraitLarge">
            Selamat Datang ke Malaysia
          </div>
          <div className="textVariantPotraitLarge2">Welcome to Malaysia</div>
        </div>

        <div style={{ height: "70vh", maxWidth: "80%", margin: "auto" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div style={{ width: "600px" }}>
                <KSignBoard />
              </div>
            </div>
            <div style={{ width: "70%" }}>
              <img src={Box} style={{ width: "100%", height: "100%" }} />
            </div>
          </div>
        </div>
        <div className="screen7-footer">
          <div className="textVariantSectionSmall">
            <DateTime />
          </div>
        </div>
      </div>
    </div>
  );
};
