import styles from "./screen.module.scss";
import { useEffect, useState } from "react";
import { refreshInternal } from "@utils/Constants";
import { getIOTData } from "@services/iot.service";
import { Col, Image, Row } from "antd";
import DateTime from "./DateTime";
import Lottie from "react-lottie";
import animationData from "@assets/Json/Bus_time _Line_9.json";
import BussData from "@assets/Json/43_inch_i_sign_board_landscape.json";
import Bus from "@assets/SVG/Bus.png";
import Terminalleft from "@assets/SVG/Terminalleft.svg";
import Screen10_Arrival from "@assets/SVG/Screen10_Arrival.svg";
import CurvedEdge from "@assets/SVG/Curved_Edge.svg";
import busC from "@assets/SVG/bus_C.svg";

export default function ISignBoard() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="w-100">
      <Lottie options={defaultOptions} />
    </div>
  );
}

export const IBusBoard = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BussData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} />
    </div>
  );
};

export const Screen10 = () => {
  const [busCount, setBusCount] = useState<any>("0");
  useEffect(() => {
    getAvailableCount();
    const intervalId = setInterval(() => {
      getAvailableCount();
    }, refreshInternal);
    return () => clearInterval(intervalId);
  }, []);

  const getAvailableCount = () => {
    getIOTData("/bus_count_rapidkl")
      .then((res: any) => {
        if (res && res.data && res.data.CP_Departure) {
          let value: any = res.data.CP_Departure;
          // value = Number(value) < 9 ? "0" + value : value;
          setBusCount(value);
        }
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  return (
    <div
      className="screen9"
      style={{
        height: "100vh",
        background: styles.darkBlue,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <Row style={{ height: "13vh" }}>
        <div
          style={{
            background: styles.darkBlue,
            color: "white",
            width: "100%",
            border: "10px solid rgb(36, 46, 105)",
          }}
        >
          <div
            style={{ width: "100%", marginTop: "60px" }}
            className="textVariantPotraitLarge-2"
          >
            {" "}
            <div
              className="headerI"
              style={{ margin: "15px 0px 0px 0px", width: "100%" }}
            >
              Jumpa Lagi
            </div>
            <div
              className="headertwoI"
              style={{
                margin: "15px 0px 0px 0px",
                fontWeight: "200",
                fontSize: "60px",
              }}
            >
              See You Again
            </div>
          </div>
        </div>
      </Row>
      <Row
        style={{
          background: styles.darkBlue,
          display: "flex",
          justifyContent: "space-evenly",
          height: "50vh",
          paddingTop: "65px",
          marginTop: "-3px",
        }}
      >
        <Col style={{ width: "18%", position: "relative", top: "-45px" }}>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Image
              style={{ width: "75%" }}
              className="busOutline"
              src={Bus}
              preview={false}
            ></Image>
            <img src={Terminalleft} style={{ width: "100%" }} />
          </Row>
          
        </Col>
        <Col style={{ width: "50%" }}>
          <Row
            style={
              {
                // display: "flex",
                // justifyContent: "center",
                // textAlign: "center",
              }
            }
          >
            <Col span={4} style={{}}></Col>

            <Col span={16} style={{}}>
              {/* //width: "55%", marginLeft: "275px" */}
              <div
                className="textVariantPotraitLarge-2"
                style={{
                  backgroundImage: "url(" + CurvedEdge + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                  backgroundPosition : "center",
                  borderRadius : 20,
                  display: "flex",
                }}
              >
                <div
                  className="headerI"
                  style={{
                    width: "100%",
                    margin: "40px 0px 0px 0px",
                    textAlign: "start",
                    padding: "35px",
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "25%", position: "relative", top: -20 }}>
                    <Image
                      className="busOutline"
                      src={busC}
                      preview={false}
                    ></Image>
                  </div>
                  <div
                    style={{
                      width: "25%",
                      fontSize: "100px",
                      position: "relative",
                      top: -20,
                    }}
                  >
                    {busCount}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      position: "relative",
                      top: -20,
                      left: "-80px",
                    }}
                  >
                    <div style={{ fontSize: "48px" }}>Bas Tersedia</div>
                    <div
                      style={{
                        fontSize: "42px",
                        fontStyle: "italic",
                        fontWeight: "500",
                      }}
                    >
                      Available Buses
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={4} style={{}}></Col>
          </Row>
          <Row className="busGif" style={{ marginTop: "130px" }}>
            <ISignBoard />
          </Row>
          <Row
            style={{
              width: "85%",
              margin: "0px auto",
              display: "flex",
              alignItems: "end",
              marginTop: "70px",
            }}
          >
            <Col
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="textVariantPotraitLarge-2">
                {" "}
                <div
                  className="headerI"
                  style={{ margin: "30px 0px 0px 0px", textAlign: "start" }}
                >
                  Masa Perjalanan
                </div>
                <div
                  className="headertwoI"
                  style={{ margin: "20px 0px 0px 0px", textAlign: "start" }}
                >
                  Journey Time
                </div>
              </div>
            </Col>
            <Col style={{ width: "34%" }}>
              <div className="textVariantPotraitLarge-2">
                {" "}
                <div
                  className="headerI"
                  style={{
                    margin: "30px 0px 0px 0px",
                    textAlign: "start",
                    padding: "60px",
                    backgroundColor: "#E82176",
                    borderTopRightRadius: "100px",
                    fontSize: "100px",
                  }}
                >
                  3.5
                  <i
                    style={{
                      fontWeight: "400",
                      fontSize: "75px",
                      marginLeft: "30px",
                    }}
                  >
                    min
                  </i>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          style={{
            width: "25%",
            display: "flex",
            alignItems: "center",
            marginBottom: "65px",
          }}
        >
          <Image
            src={Screen10_Arrival}
            preview={false}
            width={1100}
            height={950}
          />
        </Col>
      </Row>
      <Row
        style={{
          width: "100%",
          height: "13vh",
          background: styles.darkBlue,
          border: "2px solid rgb(36, 46, 105)",
          display: "flex",
          justifyContent: "center",
          marginTop: "-4px",
        }}
      >
        <div
          style={{ marginTop: "150px", fontSize: "64px" }}
          className="textVariantDate"
        >
          <DateTime></DateTime>
        </div>
      </Row>
    </div>
  );
};
