import styles from "./screen.module.scss";
import Bus from "@assets/SVG/Bus.png";
import Man_Walking from "@assets/SVG/Man_Walking.svg";
import { Col, Image, Row, Space } from "antd";
import DateTime from "./DateTime";
import ScreenOneAnimationData from "@assets/SVG/75_Arrow_Extendable.gif";
import PintuGatesCap from "@assets/SVG/pintu_gates_cap.svg";
import PintuGatesLower from "@assets/SVG/pintu_gates_lower.svg";
import { useEffect, useState } from "react";
import { getIOTData } from "@services/iot.service";
import { refreshInternal } from "@utils/Constants";

export const Screen1 = () => {
  const [busCount, setBusCount] = useState<any>("00");
  useEffect(() => {
    getAvailableCount();
    const intervalId = setInterval(() => {
      getAvailableCount();
    }, refreshInternal);
    return () => clearInterval(intervalId);
  }, []);

  const getAvailableCount = () => {
    getIOTData("/bus_count_rapidkl")
      .then((res: any) => {
        if (res && res.data && res.data.CP_Departure) {
          let value: any = res.data.CP_Departure;
          value = Number(value) < 9 ? "0" + value : value;
          setBusCount(value);
        }
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  return (
    <div
      className="screen1"
      style={{
        background: styles.darkBlue,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "10vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="text-center">
          <div className="header" style={{ marginBottom: 0 }}>
            Jumpa Lagi
          </div>
          <div className="headertwo">See You Again</div>
        </div>
      </div>
      <div
        style={{
          height: "80vh",
          width: "90%",
          margin: "auto",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", paddingTop : "10%" }}>
          <div className="busAndGateContainer">
            <div className="flexBetween">
              <Image
                className="busOutline"
                src={Bus}
                preview={false}
                style={{ marginLeft: 20 }}
              />
              <div className="gateContainer flexCenter">
                <div>
                  <div className="texttitleSide1">Bas Tersedia</div>
                  <div
                    style={{ margin: "20px 0px" }}
                    className="texttitleSide3"
                  >
                    Available Buses
                  </div>
                  <div className="texttitleSide2">{busCount}</div>
                </div>
              </div>
            </div>
            <div className="busContent flexCenter directionColumn">
              <div className="textTitle1">Bas ke pintu</div>
              <div style={{ margin: "20px 0px" }} className="textTitles2">
                Buses to gates
              </div>
              <div className="textTitle1">C1 - C37</div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{ width: 580 }}>
            <Image src={ScreenOneAnimationData} preview={false} />
          </div>
          <div
            className="businessClassContainer"
            style={{ position: "relative" }}
          >
            <div style={{position : "absolute", top: 0}}>
              <div className="flexBetween">
                <Image
                  className="busOutline"
                  src={Bus}
                  preview={false}
                  style={{ marginLeft: 20 }}
                />
                <div className="gateContainer flexCenter">
                  <div>
                    <div className="texttitleSide1">Bas Tersedia</div>
                    <div
                      style={{ margin: "20px 0px" }}
                      className="texttitleSide3"
                    >
                      Available Buses
                    </div>
                    <div className="texttitleSide2">02</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="busContent directionColumn">
              <div
                className="textTitle1"
                style={{ textTransform: "uppercase" }}
              >
                Kelas Pertama &
              </div>
              <div
                className="textTitle1"
                style={{ textTransform: "uppercase" }}
              >
                Perniagaan
              </div>
              <div
                style={{ marginTop: 20, textTransform: "uppercase" }}
                className="textTitles2"
              >
                First &
              </div>
              <div
                style={{ marginBottom: 20, textTransform: "uppercase" }}
                className="textTitles2"
              >
                Business Class
              </div>
              <div style={{ maxWidth: "70%", marginBottom: 20 }}>
                <Image src={PintuGatesCap} preview={false} />
              </div>
              <div className="textTitle1 textTitle3">C1 - C37</div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "10vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          {/* <div
            className="textVariant1 text-center"
            style={{ fontSize: "41px" }}
          >
            Jalan terus melewati
          </div>
          <div
            className="textVariant1 text-center"
            style={{ fontSize: "41px" }}
          >
            kedai bebas cukai
          </div>
          <div>
            <div
              className="textTitles3 text-center"
              style={{ marginTop: "1.5rem" }}
            >
              Go around the <br /> duty-free shop
            </div>
          </div> */}
          <div className="textVariantDate" style={{ marginTop: "1.5rem" }}>
            <DateTime />
          </div>
        </div>
      </div>
      {/* <div
        style={{
          background: styles.darkBlue,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="text-center">
          <div className="header" style={{ margin: "30px 0px 19px 0px" }}>
            Jumpa Lagi
          </div>
          <div className="headertwo" style={{ margin: "0px 0px 30px 0px" }}>
            See You Again
          </div>
        </div>
        <div className="busAndGateContainer">
          <div className="flexBetween">
            <Image className="busOutline" src={Bus} preview={false} />
            <div className="gateContainer flexCenter">
              <div>
                <div className="texttitleSide1">Bas Tersedia</div>
                <div style={{ margin: "20px 0px" }} className="texttitleSide3">
                  Available Buses
                </div>
                <div className="texttitleSide2">{busCount}</div>
              </div>
            </div>
          </div>
          <div className="busContent flexCenter directionColumn">
            <div className="textTitle1">Bas ke pintu</div>
            <div style={{ margin: "20px 0px" }} className="textTitles2">
              Buses to gates
            </div>
            <div className="textTitle1">C1 - C37</div>
          </div>
        </div>
        <div style={{ height: 402, width: 280 }}>
          <Image src={newAnimationData} preview={false} />
        </div>
        <div style={{ margin: "350px 0px 0px 0" }}>
          <Row>
            <Col>
              <div
                className="textVariant1"
                style={{ fontSize: "41px", textAlign: "center" }}
              >
                Jalan terus melewati <br /> kedai bebas cukai
              </div>
              <div
                className="textTitles3"
                style={{ marginTop: "1.5rem", textAlign: "center" }}
              >
                Go around the <br /> duty-free shop
              </div>
            </Col>
          </Row>
        </div>
        <div className="textVariantDate" style={{ marginTop: "20px" }}>
          <DateTime />
        </div>
      </div> */}
    </div>
  );
};
