import styles from './screen.module.scss';
import { Col, Image, Row, Space, Typography } from 'antd';
import DateTime from './DateTime';
import Lottie from 'react-lottie';
import animationData from '@assets/Json/43_H.json';
import Box from '@assets/SVG/Box.svg';
import Group from '@assets/SVG/Group.svg';
import pLine from '@assets/SVG/PLine.svg';
import info from '@assets/SVG/info.svg';

export default function GSignBoard() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<div>
			<Lottie options={defaultOptions} />
		</div>
	);
}

export const Screen6 = () => {
	return (
		<div
			className="screen7"
			style={{
				background: styles.blue,
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width:"100%"
			}}
		>
			<div style={{ background: styles.blue }}>
				<div className="textVariantPotraitLarge">Selamat Datang ke Malaysia</div>
				<div className="textVariantPotraitLarge2">Welcome to Malaysia</div>
				<Row justify="center" gutter={24} align="middle" >
					<Col lg={6} style={{ textAlign: 'center', marginRight: '3rem' }}>
						<Image style={{marginBottom:"20px"}} src={info} preview={false} />
						<div style={{marginBottom:"20px"}} className="infoText">Kaunter Penerangan</div>
						<div style={{marginBottom:"30px"}} className="infoText2">Information Counter</div>
						<GSignBoard />
						<div className='Arivals' style={{ position: 'absolute', right: '16px', marginTop: '-169px' }}>Ketibaan </div>
            <div className='Arivals2' style={{ position: 'absolute', right: '51px', marginTop: '-80px' }}> Arrivals</div>
					</Col>
					<Col lg={7} style={{ marginLeft: '3rem' }}>
						<Image src={Box} preview={false} width={1100} height={1000} />
					</Col>
				</Row>
				<div>
					<Image style={{marginTop:"50px"}} src={pLine} preview={false} />
				</div>
				<div style={{ display: 'flex', justifyContent: 'center', margin: '40px 90px' }}>
					<Image src={Group} preview={false} />
				</div>
				<div className="textVariantSectionSmall" style={{ marginTop: '80px'}}>
					<DateTime />
				</div>
			</div>
		</div>
	);
};
