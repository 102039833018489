import axios from "axios";
import { apiKey, apiHost } from "@utils/Constants";

export const getIOTData = (apiUrl: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(apiHost + apiUrl, {
        headers: {
          "api-key": apiKey,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
