import styles from './screen.module.scss';
import { Col, Image, Row } from 'antd';
import DateTime from './DateTime';
import Lottie from 'react-lottie';
import info from '@assets/SVG/info.svg';
import animationData from '@assets/Json/43_E.json';
import Box from '@assets/SVG/Box.svg';
import Group from '@assets/SVG/Group.svg';
import pLine from '@assets/SVG/PLine.svg';

export default function ESignBoard() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<div>
			<Lottie options={defaultOptions} />
		</div>
	);
}

export const Screen3 = () => {
	return (
		<div
			className="screen7"
			style={{
				background: styles.blue,
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width:"100%"
			}}
		>
			<div style={{ background: styles.blue }}>
				<div className="textVariantPotraitLarge">Selamat Datang ke Malaysia</div>
				<div className="textVariantPotraitLarge2">Welcome to Malaysia</div>
				<Row justify="center" align="middle">
					<Col lg={6} style={{ textAlign: 'center', marginRight: '4rem' }}>
						<div style={{ paddingRight: '12rem' }}>
							<Image src={info} preview={false} />
							<div style={{marginTop:"15px"}} className="infoText">Kaunter Penerangan</div>
							<div style={{marginTop:"15px"}} className="infoText2">Information Counter</div>
						</div>
						<ESignBoard />
					</Col>
					<Col lg={7} style={{ marginLeft: '2rem' }}>
						<Image src={Box} preview={false} width={1200} height={1100} />
					</Col>
				</Row>
				<div>
					<Image src={pLine} style={{height:"4px",marginTop:"20px"}} preview={false} />
				</div>
				<div style={{ display: 'flex', justifyContent: 'center', margin: '40px 0px' }}>
					<Image src={Group} preview={false} />
				</div>
				<div className="textVariantSectionSmall " style={{marginTop: "80px"}}>
					<DateTime />
				</div>
			</div>
		</div>
	);
};
