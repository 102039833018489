import styles from "./screen.module.scss";
import Bus from "@assets/SVG/Bus.png";
import VectorF from "@assets/SVG/VectorF.png";
import VectorB from "@assets/SVG/VectorB.png";
import VectorC from "@assets/SVG/VectorC.png";
import VectorE from "@assets/SVG/VectorE.png";
import Man_Walking from "@assets/SVG/Man_Walking.svg";
import { Col, Image, Row, Space } from "antd";
import DateTime from "./DateTime";
import Lottie from "react-lottie";
import animationData from "@assets/Json/75_B.json";
import { useEffect, useState } from "react";
import { refreshInternal } from "@utils/Constants";
import { getIOTData } from "@services/iot.service";

export default function ASignBoard() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} />
    </div>
  );
}

export const Screen2 = () => {

  const [busCount, setBusCount] = useState<any>("00");
  useEffect(() => {
    getAvailableCount();
    const intervalId = setInterval(() => {
      getAvailableCount();
    }, refreshInternal);
    return () => clearInterval(intervalId);
  }, []);

  const getAvailableCount = () => {
    getIOTData("/bus_count_rapidkl")
      .then((res: any) => {
        if (res && res.data && res.data.CP_Departure) {
          let value: any = res.data.CP_Departure;
          value = Number(value) < 9 ? "0" + value : value;
          setBusCount(value);
        }
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };
  
  return (
    <div
      style={{
        background: styles.darkBlue,
        height: "100vh",
        display: "flex",
        alignItems: "stretch",
        justifyContent: "space-around",
      }}
    >
      <div
        style={{
          background: styles.darkBlue,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="header" style={{ margin: "30px 0px 19px 0px" }}>
          Jumpa Lagi
        </div>
        <div className="headertwo" style={{ margin: "0px 0px 30px 0px" }}>
          See You Again
        </div>
        <div className="busAndGateContainer">
          <div className="flexBetween">
            <Image className="busOutline" src={Bus} preview={false}></Image>
            <div className="gateContainer flexCenter">
              <div>
                <div className="texttitleSide1">Bas Tersedia</div>
                <div style={{ marginTop: "20px" }} className="texttitleSide3">
                  Available Buses
                </div>
                <div style={{ marginTop: "20px" }} className="texttitleSide2">
                  {busCount}
                </div>
              </div>
            </div>
          </div>
          <div className="busContent flexCenter directionColumn">
            <div style={{ marginTop: "20px" }} className="textTitle1">
              Bas ke pintu
            </div>
            <div style={{ marginTop: "20px" }} className="textTitles2">
              Buses to gates
            </div>
            <div style={{ marginTop: "20px" }} className="textTitle1">
              C1 - C37
            </div>
          </div>
        </div>
        <div style={{ height: 523, width: 489, marginLeft: "-100px" }}>
          <ASignBoard />
        </div>
        <div className="contentContainer">
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Col>
              <div className="titleContent">
                <div className="textVariant1" style={{margin : "auto"}}>
                  <div style={{ fontSize: "40px", marginTop: "20px" }}>
                    Gunakan eskalator
                  </div>
                  <div
                    style={{
                      fontSize: "35px",
                      marginLeft: "-511px",
                      marginTop: "-60px",
                    }}
                    className="textTitles3b"
                  >
                    Use the escalator
                  </div>
                </div>
              </div>
              <div className="titleContent" style={{ marginTop: 80 }}>
                <div className="textVariant2" style={{margin : "auto"}}>
                  <div
                    style={{
                      fontSize: "40px",
                      // marginLeft: "10px",
                      marginTop: "-300px",
                    }}
                  >
                    Lif untuk
                  </div>
                  <div
                    style={{
                      fontSize: "35px",
                      marginTop: "-90px",
                      marginLeft: "-280px",
                    }}
                    className="textTitles3b textTitles4b"
                  >
                    Lift for
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col
              className="vectorGroup1"
              style={{
                textAlign: "center",
                marginRight: "4rem",
                marginTop: "-350px",
                width: "580px",
                height: "180px",
              }}
            >
              <div style={{ width: "50px", height: "110px" }}>
                <Image
                  className="busOutline"
                  src={VectorB}
                  preview={false}
                ></Image>
              </div>
              <div style={{ width: "80px", height: "110px" }}>
                <Image
                  className="busOutline"
                  src={VectorE}
                  preview={false}
                ></Image>
              </div>
              <div style={{ width: "90px", height: "120px" }}>
                <Image
                  className="busOutline"
                  src={VectorF}
                  preview={false}
                ></Image>
              </div>
              <div style={{ width: "110px", height: "110px" }}>
                <Image
                  className="busOutline"
                  src={VectorC}
                  preview={false}
                ></Image>
              </div>
            </Col>
          </Row>
        </div>
        <div
          style={{ marginTop: "-50px", textAlign: "center" }}
          className="textVariantDate"
        >
          <DateTime></DateTime>
        </div>
      </div>
    </div>
  );
};
