import { Routes, Route, Navigate } from "react-router-dom";
import { GlobalLayout, PageNotFound } from "@components/index";

import { globalRoutes } from "./Routes";

export function GlobalRouter() {
  return (
    <Routes>
      {/* Replace this with error pages */}
      <Route path="*" element={<PageNotFound />} />
      <Route element={<GlobalLayout />}>
        {globalRoutes.map(({ path, element, ...res }) => (
          <Route path={path} {...res} element={element} key={path} />
        ))}
      </Route>
    </Routes>
  );
}
